a {
  text-decoration: none;
  font-weight: bold;
  color: var(--primary);
}

a:hover {
  color: var(--primary-dark);
}

nav {
  width: 80vw;
  padding: 1.4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav > img {
  width: 120px;
  cursor: pointer;
}

@media (max-width: 542px) {
  nav > img {
    width: 100px;
  }
}

@media (max-width: 430px) {
  nav > img {
    width: 90px;
  }
}

@media (min-width: 1438px) {
  nav {
    width: 1150px;
  }
}

@media (max-width: 890px) {
  nav {
    width: 90vw;
  }
}

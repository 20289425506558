.form-section {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(2, 48%);
    column-gap: 4%;
    padding: 1.5rem;
}

.form-section-col-l,
.form-section-col-r {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (min-width: 1438px) {
    .form-section {
        width: 1150px;
        padding: 1.5rem 3rem;
    }
}

@media (max-width: 890px) {
    .form-section {
        width: 90vw;
        grid-template-columns: repeat(1, 100%);
    }
}

@media (max-width: 980px) {
    .form-section {
        grid-template-columns: repeat(1, 100%);
    }
}

.auth-alert {
    position: fixed;
    height: fit-content;
    width: fit-content;
    left: 50% !important;
    transform: translateX(-50%);
    margin-top: 1.2rem;
}

.auth-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

#auth-card {
    padding: 2rem;
    width: 750px;
    border-radius: 15px;
}

.auth-logo {
    max-width: 100px;
}

form>#auth-btns {
    display: grid;
    grid-template-columns: 35% 30% 35%;
    align-items: center;
    width: 100%;
}

#auth-btns-1 {
    justify-self: start;
}

#auth-btns-2 {
    justify-self: center;
}

#auth-btns-3 {
    justify-self: end;
}

@media (max-width: 541px) {
    #auth-card {
        font-size: 0.7rem;
    }

    form>#auth-btns {
        grid-template-columns: 50% 50%;
    }

    #auth-btns-2 {
        display: none;
    }

    form>hr {
        margin-top: 1.4rem;
    }
}

@media (min-width: 542px) {
    #auth-btn-mobile {
        display: none;
    }
}

@media (max-width: 920px) {
    #auth-card {
        max-width: 85vw;
    }
}

@media (max-width: 430px) {
    #auth-card {
        padding: 1.2rem;
    }
}

@media (max-width: 980px) {
    .auth-alert {
        width: 480px;
    }
}

@media (max-width: 560px) {
    .auth-alert {
        width: 90vw;
    }
}

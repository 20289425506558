:root {
  --primary: rgba(11, 11, 159, 0.600);
  --primary-light: rgba(11, 11, 159, 0.214);
  --primary-dark: rgba(11, 11, 159, 0.800);
  --font-primary: 'Montserrat', sans-serif;
}

* {
  box-sizing: border-box;
}

html,
body {
    min-height: 100vh;
    margin: 0;
    font-family: var(--font-primary);
    font-size: large;
    background: #fafaf9;
}

.vacation-main {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto;
    gap: 1.4rem;
    width: 80vw;
    margin-top: 1.1rem;
}

.vacation-nav {
    width: 100%;
}

.vacation-main>#item-1 {
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.vacation-main>#item-2 {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
}

.vacation-main>#item-3 {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
}

.vacation-main>#item-4 {
    grid-row: 3 / span 1;
    grid-column: 1 / span 2;
}

.vacation-card,
.vacation-card-top {
    padding: 1.4rem;
    border-radius: 15px !important;
}

.vacation-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vacation-card-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
}

.vacation-card-top>img {
    width: 17%;
}

.vacation-card-top>a {
    text-align: 'left';
    margin: 0 0 0 1.4rem
}

@media (min-width: 1438px) {
    .vacation-main {
        width: 1150px;
    }
}

@media (max-width: 890px) {
    .vacation-main {
        grid-template-rows: auto auto auto;
        grid-template-columns: 100%;
        width: 90vw
    }

    .vacation-nav {
        width: 90vw;
    }

    .vacation-main>#item-2 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }

    .vacation-main>#item-3 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
    }

    .vacation-main>#item-4 {
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
    }
}

@media (max-width: 940px) {
    .vacation-card-top>a {
        font-size: 1rem;
    }
}

@media (max-width: 700px) {
    .vacation-card-top {
        flex-direction: column;
    }

    .vacation-card-top>img {
        width: 28%;
    }

    .vacation-card-top>a {
        font-size: 0.9rem;
        margin: 1.15rem 0 0 0;
    }
}

@media (max-width: 620px) {
    .vacation-card-top {
        padding: 0.8rem;
    }

    .vacation-card-top>a {
        font-size: 0.8rem;
    }
}

@media (max-width: 571px) {
    .vacation-card-top>img {
        width: 31%;
    }

    .vacation-card-top>a {
        font-size: 0.7rem;
    }
}

@media (max-width: 510px) {
    .vacation-card-top>img {
        width: 38%;
    }

    .vacation-card-top>a {
        font-size: 0.65rem;
    }
}

@media (max-width: 420px) {
    .vacation-card-top {
        padding: 0.5rem;
    }

    .vacation-card-top>img {
        width: 40%;
    }
}

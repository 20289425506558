.sig-block,
.sig-canvas {
  width: 400px;
  height: 200px;
}

.overlay-content {
  width: 400px !important;
}

.sig-canvas {
  border: dashed;
  border-width: 2px;
}

#sig-clear {
  position: relative;
  bottom: 40px;
  left: calc(-50% + 23px);
}

#Approve-Request-MuiCardHeader .MuiCardHeader-subheader {
  width: fit-content;
  color: #f97316;
}

@media (max-width: 500px) {
  .overlay-content {
    width: 240px !important;
  }

  .sig-block,
  .sig-canvas {
    width: 240px;
    height: 120px;
  }
}

.timeline-container {
  margin-top: 3.3rem;
  width: 80vw;
  height: 50vh;
  margin-bottom: 1.5rem;
}

@media (min-width: 1438px) {
  .timeline-container {
    width: 1400px;
  }
}

@media (max-width: 890px) {
  .timeline-container {
    width: 90vw;
  }
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: rgba(199, 197, 179, 0.5);
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even:first-child,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even:first-child{
  background: #6365f128;
  border-bottom: 3px double #b0b0b0;
}

.rct-sidebar-row{
  font-size: small;
}

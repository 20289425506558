.main-calendar {
    border-left: solid;
    border-width: 2px;
    border-color: #e5e7eb;
    margin-left: 0.6rem;
    padding-left: 0.6rem;

    border-right: solid;
    border-width: 2px;
    border-color: #e5e7eb;
    margin-right: 0.6rem;
    padding-right: 0.6rem;
}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 50px);
    grid-template-rows: 30px;
    justify-items: center;
    align-items: center;
}

.calendar {
    display: grid;
    grid-template-columns: repeat(7, 50px);
    grid-template-rows: repeat(6, 40px);
    justify-items: center;
    align-items: center;
}

.calendar>div {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    border-top: solid;
    border-width: 1px;
    border-color: #e5e7eb;
}

.calendar>div>h5,
.calendar-days>h5 {
    margin: 0;
    font-weight: normal;
    /*not sure if there's a better way*/
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding-right: 0.05rem;
}

.calendar-days>h5 {
    color: #9ca3af;
}

.calendar-weekend-txt {
    color: #d1d5db;
}

.calendar-weekend-bg {
    background-color: #f9fafb;
}

.calendar-today {
    font-weight: 800 !important;
    text-decoration: underline 1px;
}

.calendar-vacation {
    background-color: #818cf8;
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

@media (max-width: 1438px) {
    .previous-calendar {
        display: none;
    }

    .main-calendar {
        border-left: none;
    }
}

@media (min-width: 1438px) {
    .calendar-back-btn-mobile {
        display: none !important;
    }
}

@media (max-width: 992px) {
    .next-calendar {
        font-size: medium;
    }

    .main-calendar {
        font-size: medium;
    }

    .calendar {
        grid-template-columns: repeat(7, 40px);
        grid-template-rows: repeat(6, 32px);
    }

    .calendar-days {
        grid-template-columns: repeat(7, 40px);
    }

    .calendar-vacation {
        height: 24px;
        width: 24px;
    }
}

@media (max-width: 730px) {
    .next-calendar {
        display: none;
    }

    .main-calendar {
        border-right: none;
    }
}

@media (min-width: 730px) {
    .calendar-next-btn-mobile {
        display: none !important;
    }
}
#leave-request-table {
    width: 80vw;
    max-height: 65vh;
}

@media (min-width: 1438px) {
    #leave-request-table {
        width: 1400px;
    }
}

@media (max-width: 890px) {
    #leave-request-table {
        width: 90vw;
    }
}

#leave-history {
    width: 100%;
    display: grid;
    grid-template-rows: 10% 90%;
    align-items: center;
    justify-content: center;
    padding-top: 1.2rem;
}

.leave-history-table {
    display: table;
    width: 450px;
    table-layout: fixed;
    margin-top: 0.8rem;
}

.leave-history-table,
.leave-history-table * {
    border-collapse: collapse;
    border: none;
}

.leave-history-table-row {
    display: table-row;
    height: 1.6rem;
}

.leave-history-table-column-1 {
    display: table-cell;
    width: 85%;
    text-align: left;
}

.leave-history-table-column-2 {
    display: table-cell;
    width: 15%;
}

@media (max-width: 970px) {
    .leave-history-table {
        width: 390px;
    }

    #leave-history>h3 {
        font-size: 1rem;
    }

    .leave-history-table-column-1,
    .leave-history-table-column-2 {
        font-size: 0.9rem;
    }
}

@media (max-width: 890px) {
    .leave-history-table {
        width: 460px;
    }

    #leave-history>h3 {
        font-size: 1.2rem;
    }

    .leave-history-table-column-1,
    .leave-history-table-column-2 {
        font-size: 1.05rem;
    }
}

@media (max-width: 570px) {
    .leave-history-table {
        width: 325px;
    }

    #leave-history>h3 {
        font-size: 0.9rem;
    }

    .leave-history-table-column-1,
    .leave-history-table-column-2 {
        font-size: 0.75rem;
    }
}

@media (max-width: 380px) {
    .leave-history-table {
        width: 300px;
    }

    #leave-history>h3 {
        font-size: 0.85rem;
    }

    .leave-history-table-column-1,
    .leave-history-table-column-2 {
        font-size: 0.7rem;
    }
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1.2rem;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.App>#loading-token-verification {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

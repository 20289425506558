@media (max-width: 530px) {
    .request-table-switch>button {
        font-size: 0.7rem;
    }
}

@media (max-width: 400px) {
    .request-table-switch>button {
        font-size: 0.55rem;
    }
}

@media (max-width: 800px) {
    .timeline-container {
        display: none;
    }
}
